import React, { useCallback, useEffect, useState } from "react";
import CustomDataGrid from "../../atoms/CustomDataGrid";
import PageCard from "../../atoms/PageCard";
import PageHeader from "../../atoms/PageHeader";
import Search from "../../atoms/Search";
import Pagination from "../../atoms/Pagination";
import style from "./index.module.scss";
import CustomButton from "../../atoms/CustomButton";
import Add from "../../icons/add";
import { useNavigate } from "react-router-dom";
import { deleteData, getData } from "../../../services";
import { useCookies } from "react-cookie";
import EditIcon from "../../icons/editIcon";
import EyeIcon from "../../icons/eyeIcon";
import "./index.module.scss";
import DeleteIcon from "../../icons/deleteIcon";
import Modal from "../../atoms/Modals/Modal";
import View from "./ViewPlans/view.jsx";
import NoContentCard from "../../atoms/NoContentCard";
import { useSelector } from "react-redux";
import { RoleData } from "../../../utils/staticData/roleData.js";

const PlansAndPayments = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tableData, setTableData] = useState();
  const [businessDataList, setBusinessDataList] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [modalState, setModalState] = useState({
    isViewModalOpen: false,
    modalId: "",
    name: "",
    formData: null,
  });
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const getPlansData = async () => {
    try {
      const res = await getData({
        endpoint: "Plan/getAllPlans",
        token: cookies.t,
        params: {
          page: currentPage,
          page_limit: itemsPerPage,
          search_query: searchedValue,
        },
      });

      setTableData(res?.data);
      setBusinessDataList(res);
    } catch (exp) {
      console.log("exp", exp);
    }
  };
  useEffect(() => {
    getPlansData();
  }, [currentPage, itemsPerPage, searchedValue]);

  const CustomNoRowsOverlay = React.memo(() => {
    return <NoContentCard title="No Items Added Yet!" type="Search" />;
  });

  const handleEdit = (data) => {
    navigate(`/dashboard/add-plans&payements/edit/${data?.id}`);
  };

  const handleView = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.id,
      name: `View Request Information`,
      formData: data,
    }));
  };

  const handleDelete = (data) => async () => {
    try {
      const res = await deleteData({
        endpoint: "Plan/deletePlan",
        params: {
          plan_id: data?.id,
        },
        token: cookies.t,
      });
      if (res) {
        getPlansData();
      }
    } catch (exp) {
      console.log("exp", exp);
    }
  };
  const handleSearchEmit = (searchValue) => {
    setSearchedValue(searchValue);
  };

  const columns = [
    {
      field: "name",
      headerName: "Video Name",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        <div>{params?.row?.video_name}</div>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        <div>{params?.row?.video_type}</div>;
      },
    },
    {
      field: "video Time",
      headerName: "Video Time",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>{`${params?.row?.start_time} - ${params?.row?.end_time}`}</div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {userInfo?.role !== RoleData.VIEWER && (
              <span
                className={style.editIcon}
                type="button"
                onClick={() => handleEdit(params.row)}
              >
                <EditIcon />
              </span>
            )}
            <span
              type="button"
              className={style.editIcon}
              onClick={() => handleView(params.row)}
            >
              <EyeIcon />
            </span>
            {userInfo?.role !== RoleData.VIEWER && (
              <span type="button " onClick={handleDelete(params.row)}>
                <DeleteIcon />
              </span>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <PageHeader title="Plans and Payements" />
      <PageCard>
        <div className={style.searchAddWrap}>
          <Search onSearchEmit={handleSearchEmit} />
          {userInfo?.role !== RoleData.VIEWER && (
            <CustomButton
              text="Add New"
              icon={<Add fill="white" />}
              classes={"d-flex align-items-center"}
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/add-plans&payements");
              }}
            />
          )}
        </div>
        <CustomDataGrid
          getRowId={(row) => row.id}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
          rows={tableData || []}
          columns={columns}
        />
        {businessDataList?.total_record >= 10 && (
          <Pagination
            totalItems={businessDataList?.total_record}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </PageCard>
      {modalState.isViewModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewModalOpen: false,
            }))
          }
          title={`View Plans And Payements`}
          width="642px"
          overflowStyle={{ overflow: "visible" }}
          type="visible"
          scale="0.7"
        >
          <View data={modalState.formData} />
        </Modal>
      )}
    </>
  );
};
export default PlansAndPayments;
