import Heading from "../../../atoms/Heading";
import LoginForm from "./LoginForm/index";
import style from "./index.module.scss";

const Login = () => {
  return (
    <>
      <Heading title='Login to VideoVogue Admin'/>
      <LoginForm />
    </>
  );
};

export default Login;
