import { Field, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Error from "../../../atoms/Error";

import { postData } from "../../../../services";
import toast from 'react-hot-toast'
import { confirmationStyles, errorStyles } from "../../../../assets/styles/toast";


const Form = () => {
//   const dispatch = useDispatch();
//   const { mutate, isLoading } = useForgotPassword();
//   dispatch(setLoading(isLoading));
  const [pageError, setPageError] = useState(null);
  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const lowercaseEmail = values.email.toLowerCase();
      const res = await postData({endpoint : 'AdminAuth/forgotPassword', data : null,params : {email : lowercaseEmail}});
      if(res){
        toast.success("Password reset link sent successfully", {
          duration: 1000,
          style: confirmationStyles,
        });
        resetForm();
          navigate("/auth/login");
      } else {
        toast.error("Email not registered", {
          duration: 1000,
          style: errorStyles,
        });
        setPageError(
          "This email is not registered with us. Please enter your registered email."
        );
      }
   
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        isLoading,
        touched,
        errors,
        resetForm,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <Field
              type="text"
              name="email"
              placeholder="Enter Email Address"
              className={`${
                (touched.email && errors.email) || pageError
                  ? "error-input"
                  : ""
              }`}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\s/g, "");
                setPageError(null)
              }}
            />
            {touched.email && errors.email && <Error error={errors.email} />}
            {pageError && <Error error={pageError} />}
          </div>

          <button
            className="btn btn-primary w-100"
            disabled={!isValid || !dirty || isLoading}
          >
            Send Link
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <button
            className="btn btn-outline-dark w-100"
            type="button"
            onClick={() => navigate("/auth/login")}
          >
            Back to login
          </button>
        </form>
      )}
    </Formik>
  );
};

export default Form;
