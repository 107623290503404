import React from 'react'
import PageHeader from '../../atoms/PageHeader'

const Reports = () => {
  return (
    <PageHeader title='Plans and Payments'/>

  )
}

export default Reports