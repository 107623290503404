import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const GuestGuard = ({ children }) => {
  const [cookies] = useCookies(["t", "auid"]); // Accessing the "t" cookie

  const isAuthenticated = !!cookies.t; // Checking if the "t" cookie exists

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
