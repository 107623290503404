import React from "react";

const InputLayout = ({ children, classes, style,formStyle }) => {
  return (
    <div className={`col-sm ${classes}`} style={style}>
      <div className="form-group" style={formStyle}>{children}</div>
    </div>
  );
};

export default InputLayout;
