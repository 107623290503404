import { getData } from "../../../../services";
import PageCard from "../../../atoms/PageCard";
import PageHeader from "../../../atoms/PageHeader";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";

const BusinessView = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [getBusinessData, setGetBusinessData] = useState([]);

  const getBusinessDetails = async () => {
    try {
      const res = await getData({
        endpoint: "BusinessManagement/getBusinessDetails",
        params: {
          business_id: id,
        },
        token: cookies.t,
      });
      setGetBusinessData(res);
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  useEffect(() => {
    getBusinessDetails();
  }, [id]);

  return (
    <>
      <PageHeader title="View Business" />
      <div className="form-card">
        <PageCard classes="p-4 pt-6 mb-3">
          <div className="row g-3 mb-3">
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Business Name</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.name}</div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Business Type</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.type}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Contact Name</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.user_name}</div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Contact Email</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">
                    {getBusinessData?.user_email}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Domain</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.domain}</div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">GST NUmber</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">
                    {getBusinessData?.gst_number}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Phone Number</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.phone}</div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Alternate Phone Number</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">
                    {getBusinessData?.alternate_phone}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Address</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.address}</div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">City</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.city}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">State</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.state}</div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="row">
                <div className="col-md">
                  <label htmlFor="">Country</label>
                </div>
                <div className="col-md">
                  <div className="view-muted">{getBusinessData?.country}</div>
                </div>
              </div>
            </div>
          </div>
        </PageCard>
      </div>
    </>
  );
};
export default BusinessView;
