// Import necessary libraries and components
import { Formik, Form as FormikForm, FormikValues } from "formik";

import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Error from "../../../atoms/Error";
import { postData } from "../../../../services";
import {useCookies} from 'react-cookie'
import toast from 'react-hot-toast'
import { confirmationStyles , errorStyles} from "../../../../assets/styles/toast";
import { expirationTime } from "../../../../helpers/expirationTime";

// Import custom hooks from loginService
// import { useResend, useVerify } from "../../../services/loginService";

// Define the Form component

const Form = () => {
   
  const [cookie, setCookie] = useCookies([
    "auid",
    "role",
    "cid",
    "un",
    "t",
  ]);
  // const dispatch = useDispatch();

  const [resendLoading, setResendLoading] = useState(false);

  const storedCountdownStartTime = localStorage.getItem("countdownStartTime");
  const initialResendTimer = storedCountdownStartTime
    ? Number(storedCountdownStartTime)
    : 30;

  const [resendTimer, setResendTimer] = useState(initialResendTimer);
  const [showResend, setShowResend] = useState(false);

  const navigate = useNavigate();

  const startResendTimer = () => {
    setResendTimer(30);
    setShowResend(false);
  };

  const handleResendCompleted = () => {
    startResendTimer();
  };

  const initialValues = {
    otp: "",
  };

  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .length(6, "OTP is 6 digits long")
      .required("OTP is required"),
  });

  const handleResendClick = async () => {
    try {
      // Set resendLoading to true when starting the resend process
      setResendLoading(true);
      // dispatch(setLoading(true));
      const { data: resultData, isLoading: resendLoading } = await postData({
        endpoint: "AdminAuth/resendOtp",
        data: {},
        params: { user_id: cookie.auid },
      });

      if (resultData.status) {
        // dispatch(setLoading(false));
        toast.success(resultData.message, {
          duration: 1000,
          style: confirmationStyles,
        });
        handleResendCompleted();
      }
    } catch (error) {
      console.error(error);
    } finally {
      // Set resendLoading to false when the resend process is completed (success or failure)
      setResendLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevResendTimer) => prevResendTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [resendTimer]);

  useEffect(() => {
    localStorage.setItem("countdownStartTime", resendTimer.toString());
  }, [resendTimer]);

  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSubmit = async (values ) => {
    try {
      // Assuming postData returns a promise
      const dataToSend = {
        ...values,
        user_id: cookie.auid,
      };
      // dispatch(setLoading(true));
      const { data: resultData, isLoading } = await postData({
        endpoint: "AdminAuth/verifyOtp",
        data: {},
        params: dataToSend,
      });

      if (resultData.status) {
        // dispatch(setLoading(false));
        toast.success(resultData.message, {
          duration: 1000,
          style: confirmationStyles,
        });
        navigate("/dashboard");
        setCookie("t", resultData?.data?.token, {
          path: "/",
          expires: expirationTime(),
        });
        setCookie("aun", resultData?.data?.user_name, {
          path: "/",
          expires: expirationTime(),
        });
        setCookie("role", resultData?.data?.role, {
          path: "/",
          expires: expirationTime(),
        });
        handleResendCompleted();
      } else {
        toast.error(resultData.message, { duration: 1000, style: errorStyles });
      }
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
        handleBlur,
      }) => (
        <FormikForm onSubmit={handleSubmit}>
          <input
            label="Verification Code"
            name="otp"
            id="otp"
            type="text"
            inputmode="numeric"
            pattern="\d*"
            onChange={(e) => {
              e.preventDefault();
              const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              const trimmedValue = inputValue.slice(0, 6); // Take only the first 6 digits

              // Check if the trimmed value is different to avoid triggering unnecessary re-renders
              if (trimmedValue !== values.otp) {
                setFieldValue("otp", trimmedValue);
              }
            }}
            onInput={(e) => {
              e.preventDefault();
              const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              const trimmedValue = inputValue.slice(0, 6); // Take only the first 6 digits
              e.target.value = trimmedValue;
            }}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
            placeholder="Enter Verification Code"
            style={{width : '100%'}}
          />

          {(touched.otp || isSubmitting) && errors.otp && (
            <Error error={errors.otp} />
          )}

          <button
            className="btn btn-primary w-100 mt-4"
            disabled={isSubmitting}
            type="submit"
          >
            Verify{" "}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <div className={`form-footer`}>
            <button
              className="btn btn-outline-dark"
              onClick={() => navigate(-1)}
              type="button"
            >
              Back
            </button>
            {resendTimer === 0 || showResend ? (
              <button
                className="btn btn-outline-dark"
                onClick={handleResendClick}
                type="button"
                disabled={resendLoading} // Adjust the condition based on your logic
              >
                Resend
                {resendLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            ) : (
              <button
                className="btn btn-outline-dark"
                onClick={handleResendClick}
                type="button"
                disabled // Adjust the condition based on your logic
              >
                {formatTimer(resendTimer)}
              </button>
            )}
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
