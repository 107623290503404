import PageHeader from "../../atoms/PageHeader";
import InfoCard from "../../atoms/InfoCard";
import UserIcon from "../../icons/UserIcon";
import HomeIcon from "../../icons/homeIcon";
import Payments from "../../icons/Payments";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../services";
import moment from "moment";
import DateRangePickerShortCuts from "../../atoms/dateRangePicker";

const HomePage = () => {
  const [cookies] = useCookies(["t", "bid"]);
  const todays = new Date();
  const thirtyDaysAgo = moment(todays).subtract(30, "days").startOf("day");
  const [date, setDate] = useState({
    start_date: thirtyDaysAgo.toISOString(),
    end_date: todays.toISOString(),
  });
  const [dashboardData, setDashboardData] = useState();
  const getDashboardDetails = async () => {
    try {
      const response = await getData({
        endpoint: "AdminDashboard/getDashboardDataCount",
        params: {
          start_date: date.start_date,
          end_date: date.end_date,
        },
        token: cookies.t,
      });
      setDashboardData(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDashboardDetails();
  }, [date]);
  console.log(date.start_date, date.end_date);
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <PageHeader title="Dashboard" />
        <DateRangePickerShortCuts
          setDate={setDate}
          className="charts-datapicker-container"
        />
      </div>
      <div className="info-card-container">
        <InfoCard
          color="#B1E5FC"
          svgIcon={<HomeIcon />}
          isSvg={true}
          title="Business"
          data={dashboardData?.business_count}
        />
        <InfoCard
          color="#FFD88D"
          svgIcon={<UserIcon />}
          isSvg={true}
          title="User Management"
          data={dashboardData?.user_count}
        />
        <InfoCard
          color="#FFB7B7"
          svgIcon={<Payments />}
          isSvg={true}
          title="Plans and Payments"
          data={dashboardData?.plan_count}
        />
      </div>
    </>
  );
};
export default HomePage;
