import React from 'react'
import Heading from '../../../atoms/Heading'
import Form from './Form'

const ForgotPassword = () => {
  return (
    <div>
    <Heading title='Forgot Password' description='Enter the email address you used when you joined and we’ll send you a password reset link.'/>
    <Form/>
</div>
  )
}

export default ForgotPassword