import React from 'react'
import styles from './styles.module.scss'

const Heading = ({title,subtitle,description}) => {
  return (
    <div className='mb-4'>
    <div className={styles.heading}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
    </div>
    <div className='description mt-1'>{description}</div>
    </div>
  )
}

export default Heading