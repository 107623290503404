import { Field, Formik } from "formik";
import { useState } from "react";
// import { useDispatch } from "react-redux";
import * as yup from "yup";
import Error from "../../../atoms/Error";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { postData } from "../../../../services";
import toast from "react-hot-toast";
import {
  errorStyles,
  confirmationStyles,
} from "../../../../assets/styles/toast";
// import { useCreatePassword } from "../../../services/loginService";
// import {setLoading} from "../../../slices/loaderSlice";

const Form = ({ expiry, uid, token, type }) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  // const { mutate, isLoading } = useCreatePassword();

  // dispatch(setLoading(isLoading));
  // Form
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .transform((value) => value.trim())
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Please enter the correct format"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values) => {
    const passwordcheck = values.password.replace(/^[\s]+|[\s]+$/g, "");
    const dataToSend = {
      password: passwordcheck,
      user_id: uid,
      token: token,
    };
    // mutate(dataToSend);
    try {
      const { data: resultData, isLoading } = await postData({
        endpoint: "AdminAuth/createPassword",
        data: {},
        params: dataToSend,
      });

      if (resultData.status) {
        navigate("/auth/login");
        toast.success("Password Reset Successfully", {
          duration: 1000,
          style: confirmationStyles,
        });
      }
    } catch (error) {
      // console.log(dataToSend)
    }
  };
  // ---------------------------------------------------------------------

  // Password Conditions -------------------------------------------------
  const handlePasswordBlur = (e) => {
    const newPassword = e.target.value;
    const isPasswordValid =
      newPassword.length >= 8 &&
      /[A-Z]/.test(newPassword) &&
      /[a-z]/.test(newPassword) &&
      /[0-9]/.test(newPassword) &&
      /[!@#$%^&*]/.test(newPassword);
    setIsPasswordValid(isPasswordValid);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        isValid,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-4">
            <label>New Password</label>
            <div className="input-group">
              <Field
                type={isPasswordVisible ? "text" : "password"}
                name="password"
                placeholder="Enter new Password"
                className={`${
                  touched.password && errors.password ? "error-input" : ""
                }`}
              />
              <div
                className={`input-group-append ${
                  touched.password && errors.password ? "error-input" : ""
                }`}
              >
                <span
                  className="info "
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  {isPasswordVisible ? "Hide" : "Show"}
                </span>
              </div>
            </div>
            {touched.password && errors.password && (
              <Error error={errors.password} />
            )}
          </div>

          <div
            className={`password-strength ${
              !isPasswordValid && touched.password ? "conditions-not-met" : ""
            }`}
          >
            <p className="condition-heading">Password must :</p>
            <div className="condition-wrapper">
              <div
                className={`condition ${
                  values.password.length >= 8 ? "met" : ""
                }`}
              ></div>
              <span className="condition-description">
                {" "}
                Minimum 8 characters
              </span>
            </div>
            <div className="condition-wrapper">
              <div
                className={`condition ${
                  /[A-Z]/.test(values.password) ? "met" : ""
                }`}
              ></div>
              <span className="condition-description">
                At least one uppercase character
              </span>
            </div>
            <div className="condition-wrapper">
              <div
                className={`condition ${
                  /[a-z]/.test(values.password) ? "met" : ""
                }`}
              ></div>
              <span className="condition-description">
                At least one lowercase character
              </span>
            </div>
            <div className="condition-wrapper">
              <div
                className={`condition ${
                  /[0-9]/.test(values.password) ? "met" : ""
                }`}
              ></div>
              <span className="condition-description">
                At least one numeric character
              </span>
            </div>
            <div className="condition-wrapper">
              <div
                className={`condition ${
                  /[!@#$%^&*]/.test(values.password) ? "met" : ""
                }`}
              ></div>
              <span className="condition-description">
                One special character
              </span>
            </div>
          </div>

          <div className="form-group mt-4">
            <label>Confirm New Password</label>
            <div className="input-group">
              <Field
                type={isConfirmPasswordVisible ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm new Password"
                className={`${
                  touched.confirmPassword && errors.confirmPassword
                    ? "error-input"
                    : ""
                }`}
              />
              <div
                className={`input-group-append ${
                  touched.confirmPassword && errors.confirmPassword
                    ? "error-input"
                    : ""
                }`}
              >
                <span
                  className="info "
                  onClick={() =>
                    setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                  }
                >
                  {isConfirmPasswordVisible ? "Hide" : "Show"}
                </span>
              </div>
            </div>
            {touched.confirmPassword && errors.confirmPassword && (
              <Error error={errors.confirmPassword} />
            )}
          </div>

          <button
            className="btn btn-primary w-100"
            disabled={!isValid || !dirty}
          >
            {type === "reset" ? "Reset" : "Create"} Password
          </button>
        </form>
      )}
    </Formik>
  );
};

export default Form;
