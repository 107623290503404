import React from "react";
import { useNavigate } from "react-router-dom";
import IconCircle from "../avatars/IconCircle";
import styles from "./styles.scss";

const InfoCard = ({
  icon,
  svgIcon,
  isSvg,
  color,
  title,
  data,
  unit,
  link,
  style,
  dotIcon,
  setModalState,
}) => {
  const navigate = useNavigate();

  const onClickHandle = (e) => {
    e.preventDefault();
    if (link) navigate(link);
  };

  return (
    <div
      className="info-card cursor-pointer"
      onClick={onClickHandle}
      style={style}
    >
      <div>
        {isSvg ? (
          <IconCircle
            svgIcon={svgIcon}
            color={color}
            size="56px"
            isSvg={true}
          />
        ) : (
          <IconCircle icon={icon} color={color} size="56px" iconSize="24px" />
        )}
      </div>
      <div className="info-card-detail" style={{ marginLeft: "14px" }}>
        <span
          className="text-dark heading"
          style={{ fontSize: "24px", fontWeight: "600" }}
        >
          {data}&nbsp;<span className="p-lg">{unit ? unit : ""}</span>
        </span>
        <span className="view-muted" style={{ fontSize: "14px" }}>
          {title}
        </span>
      </div>
      {dotIcon && (
        <div
          className={styles.dotIconWrap}
          onClick={() => {
            setModalState({
              modalTitle: "Products",
              isModalOpen: true,
            });
          }}
        >
          <i className="fi fi-br-menu-dots-vertical"></i>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
