import React from "react";

const PageHeader = ({ title, children }) => {
  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <h3 style={{ color: "#1A1D1F", fontWeight : '700' }}>{title}</h3>
      <div>{children}</div>
    </div>
  );
};

export default PageHeader;
