import React, { FC } from "react";
import CloseBtnIcom from "../../icons/CloseBtnIcom";
import "./modal.scss";

const CloseButton = ({ onClose }) => {
  return (
    <div className="close-btn" onClick={onClose}>
      <CloseBtnIcom />
    </div>
  );
};

const Modal = ({
  id,
  title,
  isActive,
  onClose,
  children,
  overflowStyle,
  bodyStyle,
  width = "450px",
  type,
  scale,
}) => {
  const styles = {
    width: width,
  };

  return (
    <div
      id={id}
      className={`modal ${isActive ? "active" : ""}`}
      style={{ ...overflowStyle }}
    >
      <div
        style={{
          overflow: type === "visible" ? "visible" : "hidden",
          borderRadius: "30px",
        }}
        className={scale ? "scale-the-modal" : ""}
      >
        <div
          className="modal-normal-content"
          style={{ ...styles, ...overflowStyle }}
        >
          <div className="modal-header">
            <p className="modal-heading mb-0">{title}</p>
            <CloseButton onClose={onClose} />
          </div>
          <div className="modal-normal-body" style={{ ...bodyStyle }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
