import React from "react";

const IconCircle = ({
  color,
  size,
  icon,
  iconSize,
  iconColor,
  isSvg,
  svgIcon,
}) => {
  const circleStyle = {
    backgroundColor: color,
    width: size,
    height: size,
    borderRadius: "50%", // Ensure it's a circle
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyle = {
    fontSize: iconSize,
    color: iconColor || "#1A1D1F",
  };

  return (
    <div className="icon-circle" style={circleStyle}>
      {isSvg ? (
        <span className="icon-circle-icon" style={iconStyle}>
          {svgIcon}
        </span>
      ) : (
        <span className="icon-circle-icon " style={iconStyle}>
          {icon}
        </span>
      )}
    </div>
  );
};

export default IconCircle;
